<template>
  <div style="height: 100vh">
    <vueper-slides fixed-height="100vh">
      <vueper-slide
          v-for="(image, i) in images"
          :key="i">
        <template v-slot:content>
          <div style="background-color: black; width: 100vw; height: 100vh;">
            <img :src="image" style="object-fit: contain; width: 100vw; height: 100vh;"/>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';

export default {
  components: { VueperSlides, VueperSlide },
  mounted() {
    const images = require.context('@/assets/img/transdev', false, /\.(png|jpe?g|gif|svg)$/);
    this.images = images.keys().map(key => images(key));
    console.log(this.images);
  },
  data() {
    return {
      images: [],
      options: {
        loop: true,
        autoplay: {
          delay: 2000,
        },
      },
    };
  }
};
</script>
