<template>
  <div id="app">
    <nav>

    </nav>
    <router-view></router-view>
  </div>
</template>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
